var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"img",attrs:{"align":"center","justify":"center"}},[(!_vm.loading && _vm.contenttype == 'image')?[_c('v-img',{attrs:{"height":"130","src":_vm.urlimg,"max-width":"100"},on:{"click":function($event){_vm.dialog = true}}})]:(_vm.loading && _vm.contenttype == 'image')?[_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)]:[_c('div',{staticClass:"no-image"},[_c('h4',[_vm._v("No image")])])]],2),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialogimg"},[_c('v-img',{attrs:{"src":_vm.urlimg,"contain":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnimg mx-1 my-2",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.dialog = false}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Close")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.imageFrom == 'upload'
                ? 'btnimgd mx-1 my-2'
                : ' btnimgdsms mx-1 my-2',attrs:{"fab":"","color":"primary","small":""},on:{"click":_vm.downloadUrl}},on),[_c('v-icon',[_vm._v("mdi-cloud-download")])],1)]}}])},[_c('span',[_vm._v("Donwload Image")])]),(_vm.imageFrom == 'upload')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"btnimgdelete mx-1 my-2",attrs:{"fab":"","color":"error","small":""},on:{"click":_vm.deleteImage}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,3311715153)},[_c('span',[_vm._v("Delete Image")])])]:_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }