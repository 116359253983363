<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column pa-4">
    <v-progress-linear class="my-3" v-if="loading" indeterminate />

    <v-row no-gutters>
      <v-col
        cols="5"
        class="d-flex align-center flex-column justify-center pr-3"
      >
        <v-card
          v-if="loading == false && photoId != ''"
          class="overflow-hidden"
          max-width="100%"
          max-height="320"
          outlined
        >
          <v-img contain max-height="420" max-width="320" :src="photoId">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="primary" />
              </v-row>
            </template>
          </v-img>
        </v-card>
        <v-card
          v-if="loading == false && photoId == ''"
          class="d-flex align-center justify-center"
          width="128"
          height="128"
          rounded="lg"
        >
          <v-icon color="secondary" size="120"> mdi-account </v-icon>
        </v-card>

        <div class="d-flex flex-column fill-width mt-4">
          {{/* Upload foto */}}
          <v-card
            class="overflow-hidden rounded-lg"
            :loading="uploadingPatientPhoto || loading"
          >
            <v-tabs v-model="uploadAs" grow>
              <v-tab>Upload a photo</v-tab>
              <v-tab>Take a photo</v-tab>
            </v-tabs>
            <v-card-text>
              <v-tabs-items v-model="uploadAs">
                <v-tab-item>
                  <v-file-input
                    v-model="patientPhoto"
                    class="mt-3"
                    label="Photo ID"
                    accept=".jpg,.png,.jpeg"
                    outlined
                    dense
                    @change="onFileUpload"
                  />
                </v-tab-item>
                <v-tab-item>
                  <camera-capture ref="camera" v-model="patientPhoto" />
                </v-tab-item>
              </v-tabs-items>

              <div class="d-flex justify-center m-3">
                <v-btn
                  color="primary"
                  :disabled="!havePhotoId"
                  :loading="uploadingPatientPhoto"
                  rounded
                  text
                  @click="uploadPatientPhoto"
                >
                  <v-icon left> mdi-cloud-upload </v-icon>
                  Upload
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>

      <v-col cols="7">
        <template v-if="loading">
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="160"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="80"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="160"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="80"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="160"
          />
          <v-skeleton-loader
            transition="scale-transition"
            type="text"
            width="80"
          />
        </template>

        <template v-else>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <div class="text-caption font-weight-blod text--secondary">
                Full name
              </div>
              <div class="text-body-1">
                {{ patientDetail.fullname }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <div class="text-caption font-weight-blod text--secondary">
                Email
              </div>
              <div class="text-body-1">
                {{ patientDetail.email }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4" xl="4">
              <div class="text-caption font-weight-blod text--secondary">
                Phone
              </div>
              <div class="text-body-1">
                {{ patientDetail.homePhone }}
              </div>
            </v-col>
          </v-row>

          <v-divider class="my-2" />
          <v-row>
            <v-col cols="3">
              <div class="text-caption font-weight-blod text--secondary">
                DOB
              </div>
              <div class="text-body-1">
                {{ birthDate }}
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-column">
                <span class="text-caption font-weight-blod text--secondary">
                  Weight
                </span>
                <span class="text-body-1">{{ patientDetail.weight }} lbs</span>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-column">
                <span class="text-caption font-weight-blod text--secondary">
                  Height
                </span>
                <span class="text-body-1"
                  >{{ patientDetail.height }} ft,
                  {{ patientDetail.heightInches }} in</span
                >
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-column">
                <span class="text-caption font-weight-blod text--secondary">
                  BMI
                </span>
                <span class="text-body-1">{{ bmi }}</span>
                <span :class="`text-body-1 ${colorWeight}--text`">{{
                  typebmi
                }}</span>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="d-flex flex-column">
                <span class="text-caption font-weight-blod text--secondary">
                  Gender
                </span>
                <span class="text-body-1">{{
                  patientDetail.isMale ? "Male" : "Female"
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2" />

          <v-row class="mb-2" no-gutters>
            <v-col cols="12" sm="6" md="3" lg="4" xl="4">
              <div class="text-caption font-weight-blod text--secondary">
                Referred from
              </div>
              <div class="text-body-1">
                {{ patient.howKnow != null ? patient.howKnow : "NaN" }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4" xl="4">
              <div class="text-caption font-weight-blod text--secondary">
                Prefer language
              </div>
              <div
                class="text-body-1 text-capitalize"
                v-html="patient.preferLanguage.toLowerCase()"
              ></div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4" xl="4">
              <div class="text-caption font-weight-blod text--secondary">
                Medical Location
              </div>
              <div class="text-body-1">
                {{ patient.medicalLocation.name }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2" />
          <div class="d-flex flex-column">
            <div class="text-caption font-weight-blod text--primary">
              Emergency Contact
            </div>
            <v-row class="mb-2" no-gutters>
              <v-col cols="12" sm="6" md="3" lg="4" xl="4">
                <div class="text-caption font-weight-blod text--secondary">
                  Name
                </div>
                <div class="text-body-1">
                  {{
                    patient.emergencyContact ? patient.emergencyContact : "-"
                  }}
                </div>
              </v-col>

              <v-col cols="12" sm="6" md="3" lg="4" xl="4">
                <div class="text-caption font-weight-blod text--secondary">
                  Phone
                </div>
                <div class="text-body-1 text-capitalize">
                  {{ patient.emergencyPhone ? patient.emergencyPhone : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="4" xl="4">
                <div class="text-caption font-weight-blod text--secondary">
                  Relationship
                </div>
                <div class="text-body-1 text-capitalize">
                  {{
                    patient.emergencyRelation ? patient.emergencyRelation : "-"
                  }}
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="my-2" />
          <v-row class="mb-2" no-gutters>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <div class="text-caption font-weight-blod text--secondary">
                State
              </div>
              <div class="text-body-1">
                {{ patient.mailingCity ? patient.mailingCity.state.name : "-" }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <div class="text-caption font-weight-blod text--secondary">
                City
              </div>
              <div class="text-body-1">
                {{ patient.mailingCity ? patient.mailingCity.name : "-" }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <div class="text-caption font-weight-blod text--secondary">
                Address
              </div>
              <div class="text-body-1">
                {{ patient.mailingAddress ? patient.mailingAddress : "-" }}
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <div class="text-caption font-weight-blod text--secondary">
                ZipCode
              </div>
              <div class="text-body-1">
                {{ patient.mailingZip ? patient.mailingZip : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-2" />
          <all-images
            ref="imgs"
            @uploaded="getPatient"
            @deleted="getPatient"
            :imagesfrom="'patient'"
            :uuid="patientDetail.uuid"
            :imagesProps="patientDetail.pictures"
          />
          <v-divider class="my-2" />
          <template>
            <v-row no-gutters>
              <template
                v-if="
                  patient.patientDoctor != undefined &&
                  patient.patientDoctor != null
                "
              >
                <v-col cols="6">
                  <div class="text-caption font-weight-blod text--primary">
                    {{ getCoordinatorN }}
                  </div>
                  <v-layout column wrap>
                    <v-flex xs12>
                      <div
                        class="text-caption font-weight-blod text--secondary"
                      >
                        Full name
                      </div>
                      <div class="text-body-1">
                        {{ patient.patientDoctor.fullname }}
                      </div>
                    </v-flex>
                    <!--    <v-flex xs12>
                      <div
                        class="text-caption font-weight-blod text--secondary"
                      >
                        Ocupation
                      </div>
                      <div class="text-body-1">
                        {{
                          patient.patientDoctor.ocupation != null
                            ? patient.patientDoctor.ocupation.name
                            : "Not Ocupation"
                        }}
                      </div>
                    </v-flex> -->
                    <v-flex xs12>
                      <div
                        class="text-caption font-weight-blod text--secondary"
                      >
                        Email
                      </div>
                      <div class="text-body-1">
                        {{ patient.patientDoctor.email }}
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div
                        class="text-caption font-weight-blod text--secondary"
                      >
                        Phone
                      </div>
                      <div class="text-body-1">
                        {{ patient.patientDoctor.phone }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-col>
              </template>
              <template
                v-if="patient.seller != undefined && patient.seller != null"
              >
                <v-col cols="6">
                  <div class="text-caption font-weight-blod text--primary">
                    {{ getSellerN }}
                  </div>
                  <v-layout column wrap>
                    <v-flex xs12>
                      <div
                        class="text-caption font-weight-blod text--secondary"
                      >
                        Full name
                      </div>
                      <div class="text-body-1">
                        {{ patient.seller.fullname }}
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div
                        class="text-caption font-weight-blod text--secondary"
                      >
                        Phone
                      </div>
                      <div class="text-body-1">
                        {{ getPhone(patient.seller.phone) }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-col>
              </template>
            </v-row>
          </template>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import CameraCapture from "@/components/CameraCapture/CameraCapture.vue";

import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AllImages from "@/components/Images/AllImages.vue";

export default Vue.extend({
  components: { CameraCapture, AllImages },
  name: "patient-personal-details",
  props: {
    patient: Object,
  },
  data() {
    return {
      loading: true,
      photoId: "",
      patientPhoto: new Blob(),
      uploadAs: null,
      uploadingPatientPhoto: false,
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getSellerN"]),
    ...mapState("crmMedicFormModule", ["patientDetail"]),
    birthDate() {
      const date = moment(this.patientDetail.birthDate);

      return date.format("MM/DD/YYYY");
    },
    havePhotoId() {
      const havePhotoId =
        (this.patientPhoto instanceof Blob && this.patientPhoto.size > 0) ||
        (this.patientPhoto instanceof File && this.patientPhoto.size > 0);

      return havePhotoId;
    },
    bmi() {
      if (this.patientDetail.weight == 0 && this.patientDetail.height == 0)
        return 0;
      const inchtometer = this.patientDetail.heightInches * 0.0254;
      const feettometer = this.patientDetail.height * 0.3048;
      const bmi =
        (Number(this.patientDetail.weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);
      return Number(bmi).toFixed(2);
    },
    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  watch: {
    procedure() {
      this.getPhoto();
    },
  },
  mounted() {
    this.getPhoto();
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["getPhotoId"]),
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),
    getPatient() {
      getAPI(`/patient/getPatient/${this.$route.params.uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
      });
    },
    getPhone(phone) {
      if (phone[0] == "+") {
        return phone;
      }
      const pho = "+1" + phone;
      return pho;
    },

    async getPhoto() {
      this.loading = true;
      const patientdata = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "image/jpg",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "image/jpg",
            };

      if (patientdata.avatar != null) {
        axios({
          method: "get",
          url: `${apiBaseUrl}/patient/downloadAvatar/${this.patientDetail.uuid}`,
          responseType: "blob",
          headers: auth,
        })
          .then((response) => {
            const blob = new Blob([response.data]);
            let reader = new FileReader();

            reader.onloadend = () => {
              let base64data = reader.result;

              this.photoId = base64data;
            };
            reader.readAsDataURL(blob);
            this.loading = false;
          })
          .catch(() => {
            this.patientPhoto = new Blob();
            this.loading = false;
            // notificationError();
          });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    onFileUpload(file) {
      if (file == null) {
        return;
      }
      const { name } = file;

      if (
        !name.includes(".png") &&
        !name.includes(".jpg") &&
        !name.includes(".jpeg")
      ) {
        notifyError(
          "The image format is not allowed, select <b>.png</b>, <b>.jpg</b> or <b>.jpeg</b>"
        );
        this.patientPhoto = new Blob();
      }
    },
    async uploadPatientPhoto() {
      const formDataPhotoId = new FormData();

      this.uploadingPatientPhoto = true;
      formDataPhotoId.append(
        "photo",
        this.patientPhoto,
        `photo-id-${this.patientDetail.uuid}`
      );

      await getAPI({
        method: "post",
        url: `/patient/uploadPatientAvatar/${this.patientDetail.uuid}`,
        data: formDataPhotoId,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          if (this.$refs.camera) {
            this.$refs.camera.closeCamera();
          }
          this.uploadingPatientPhoto = false;
          this.getPhoto();
        })
        .catch((error) => {
          this.uploadingPatientPhoto = false;

          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
