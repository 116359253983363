<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-row class="img" align="center" justify="center">
      <template v-if="!loading && contenttype == 'image'">
        <v-img
          height="130"
          :src="urlimg"
          max-width="100"
          @click="dialog = true"
        >
        </v-img>
      </template>
      <template v-else-if="loading && contenttype == 'image'">
        <div class="d-flex justify-center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </template>
      <template v-else>
        <div class="no-image"><h4>No image</h4></div>
      </template>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <div class="dialogimg">
        <v-img :src="urlimg" contain></v-img>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              v-on="on"
              small
              class="btnimg mx-1 my-2"
              @click="dialog = false"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Close</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              v-on="on"
              color="primary"
              small
              :class="
                imageFrom == 'upload'
                  ? 'btnimgd mx-1 my-2'
                  : ' btnimgdsms mx-1 my-2'
              "
              @click="downloadUrl"
              ><v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Donwload Image</span>
        </v-tooltip>
        <template v-if="imageFrom == 'upload'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                color="error"
                small
                class="btnimgdelete mx-1 my-2"
                @click="deleteImage"
                ><v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Image</span>
          </v-tooltip>
        </template>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { getAPI } from "@/api/axios-base";
import { notifyError, notifySuccess } from "../Notification";
export default {
  name: "card-image",
  props: {
    uuid: String,
    url: String,
    active: Boolean,
    imageFrom: String,
    imagesfrom: String,
    imagePath: String,
  },
  data() {
    return {
      overlay: false,
      dialog: false,
      loading: false,
      urlimg: "",
      typeimage: "",
      contenttype: "",
    };
  },
  methods: {
    getImage() {
      const token = this.$store.state.accessToken;
      let auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "*/*",
            };
      this.loading = true;
      axios({
        method: "get",
        url: this.url,
        responseType: "blob",
        headers: auth,
      })
        .then((res) => {
          this.contenttype = res.data.type.split("/")[0];
          this.typeimage = res.data.type.split("/")[1];

          const buffer = new Blob([res.data]);
          this.urlimg = URL.createObjectURL(buffer);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    downloadUrl() {
      const imgs = this.urlimg + "." + this.typeimage;

      const a = document.createElement("A");
      a.href = this.urlimg;
      a.target = "_blank";
      a.setAttribute("style", "display: none");
      a.download = imgs.split("/")[3];
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    deleteImage() {
      let parameters = {};
      if (this.imagesfrom == "lead") {
        {
          parameters = { leadUuid: this.uuid, path: this.imagePath };
        }
      } else {
        parameters = { patientUuid: this.uuid, path: this.imagePath };
      }

      getAPI
        .delete(`/social/deletePicture`, { params: parameters })
        .then(() => {
          notifySuccess("Image has been deleted");
          this.$emit("deleted");
          this.dialog = false;
        })
        .catch((error) => {
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getImage();
  },
};
</script>
<style lang="scss" scoped>
.img {
  margin: 5px;
}
.dialogimg {
  position: relative;
}
.btnimg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}
.btnimgd {
  position: absolute;
  top: 0;
  right: 90px;
  z-index: 999;
}
.btnimgdsms {
  position: absolute;
  top: 0;
  right: 45px;
  z-index: 999;
}
.btnimgdelete {
  position: absolute;
  top: 0;
  right: 45px;
  z-index: 999;
}

.no-image {
  display: flex;
  height: 120px;
  align-items: center;
}
</style>
